import React from "react";
import styled from "styled-components";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const StyledButton = styled.div<{ open: boolean }>`
  border: 4px solid ${colors.yellow.getHex()};
  border-right: 0px;
  background: ${colors.white.getHex()};
  position: fixed;
  // right: 75%;
  height: 6rem;
  width: 2.5rem;
  top: 25%;
  cursor: pointer;
  .wrap {
    transform: rotate(0.25turn);
    position: relative;
    top: 0.9rem;
  }
  a {
    text-decoration: none;
    color: ${colors.primary.getHex()} !important;
  }
  a: hover {
    color: ${colors.primary.getHex()} !important;
  }
  right: ${({ open }) => (open ? `75%` : `0.75rem`)};
`;

const PopOutButton = ({
  setOpen,
  isOpen
}: {
  setOpen: Function;
  isOpen: boolean;
}) => {
  return (
    <StyledButton
      onClick={() => {
        setOpen(!isOpen);
      }}
      open={isOpen}>
      <div className="wrap">
        <Typography.BLOCKQUOTE>Progress</Typography.BLOCKQUOTE>
      </div>
    </StyledButton>
  );
};

export default PopOutButton;
