import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContentfulImageQuery, Img } from "wmk-image";
import { WmkLink } from "wmk-link";
import { Video } from "../../classes/Video";
import { VideoCat } from "../../classes/VideoCat";
// import { VideoCategoryQuery } from "../../fragments/NodeVideoCategories";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import { getVideoCategoryDetails } from "../ui/PreviewCard";
// import PopOutButton from "./Buttons/PopOutButton";
import { Pillar } from "../../classes/Pillar";
import PopOutButton from "../Buttons/VideoSelectorShelfButton";
import TextButton from "../Buttons/TextButton";

const StyledVideoSelector = styled.div<{ width: string }>`
  ${({ width }) => (width ? `position: absolute;` : ``)};
  width: 100%;
  a {
    text-decoration: none;
    color: ${colors.bodyText.getHex()};
  }
  a: hover {
    color: ${colors.bodyText.getHex()};
  }
  .col {
    padding: 1rem 3rem;
  }
  .image-col {
    display: flex;
    justifycontent: center;
    margin: 2rem;
  }
  .detail-col {
    color: ${colors.bodyText.getHex()};
    position: relative;
    h2 {
      margin-bottom: 1rem;
    }
    h6 {
      margin-bottom: 1rem;
    }
  }
`;

const Background = styled(Container)<{ open: string }>`
  background: ${colors.white.getHex()};
  z-index: 10;
  position: relative;
  border-left: solid ${colors.yellow.getHex()} 0.75rem;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 25%;
  overflow-x: scroll;
  @media screen and (max-width: 991px) {
    ${({ open }) => (open ? `width: 75%; ` : `right: calc(0.75rem - 25%)`)};
  }
`;

const SelectionCircle = styled.div<{ active: boolean }>`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  border: 2px solid ${colors.green.getHex()};
  position: absolute;
  left: -3rem;
`;

const SelectionCircleFilled = styled.div`
  height: 0.65rem;
  width: 0.65rem;
  border-radius: 100%;
  background: ${colors.green.getHex()};
  position: absolute;
  left: calc(0.3rem - 3rem);
  top: 0.3rem;
`;

const Line = styled.div`
  height: 5rem;
  width: 4px;
  background: ${colors.green.getHex()};
  position: relative;
  left: -2.5rem;
  top: -0.6rem;
`;

const VideoDetailSubsection = styled.div`
  position: relative;
  left: 3rem;
  width: calc(100% - 1rem);
`;

const VideoSelector = ({
  video,
  videoCat,
  videoCats,
  pillar
}: {
  video: Video;
  videoCat: VideoCat;
  videoCats: VideoCat[];
  pillar: Pillar;
}) => {
  const data: {
    logo: ContentfulImageQuery;
  } = useStaticQuery(query);

  // const videoCats = data.videoCats.edges.map((videoCat) => {
  //   return new VideoCat(videoCat.node);
  // });

  const [viewWidth, setViewWidth] = useState(0);
  const [currentVideoCat, setCurrentVideoCat] = useState<VideoCat>(videoCat);
  const [isOpen, setIsOpen] = useState(false);
  // const [currentVideo, setCurrentVideo] = useState<Video>(
  //   video
  // );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  return (
    <>
      <StyledVideoSelector width={viewWidth > 991 ? "true" : ""}>
        {viewWidth && viewWidth < 992 && (
          <PopOutButton setOpen={setIsOpen} isOpen={isOpen} />
        )}
        <Background open={isOpen ? true : ""} fluid>
          <Row>
            <Col>
              <Typography.EYEBROW
                style={{
                  color: colors.orange.getHex(),
                  paddingBottom: 0,
                  textTransform: "uppercase"
                }}>
                {pillar.title}
              </Typography.EYEBROW>
            </Col>
          </Row>
          {videoCats.map((videoCat, i) => {
            return videoCat.description === currentVideoCat.description ? (
              <Row key={`${videoCat.title} ${i}`}>
                <Col className="detail-col">
                  <Typography.H2 style={{ color: colors.primary.getHex() }}>
                    {currentVideoCat?.title}
                  </Typography.H2>
                  <Typography.H6
                    style={{
                      color: colors.secondary.getHex(),
                      fontWeight: 600
                    }}>
                    {getVideoCategoryDetails(currentVideoCat)}
                  </Typography.H6>
                  {currentVideoCat.videos.map((vid, j) => {
                    return (
                      <VideoDetailSubsection
                        style={{ position: "relative" }}
                        key={`${vid.title} ${j}`}>
                        <SelectionCircle active={vid.title === video.title} />
                        {vid.title === video.title && <SelectionCircleFilled />}
                        <WmkLink to={`/videos/${vid.slug}`}>
                          <Typography.P
                            style={{
                              fontFamily: "Montserrat",
                              marginBottom: "1rem",
                              color: colors.primary.getHex()
                            }}>
                            {vid.title}
                          </Typography.P>
                        </WmkLink>
                        {j < currentVideoCat.videos.length - 1 && <Line />}
                      </VideoDetailSubsection>
                    );
                  })}
                  <TextButton
                    to={`/videos/${currentVideoCat.fullCategoryVideo.slug}`}
                    arrow>
                    Watch as One Video
                  </TextButton>
                </Col>
              </Row>
            ) : (
              <Row key={`${videoCat.title} ${i}`}>
                <Col>
                  <button
                    onClick={() => {
                      setCurrentVideoCat(videoCat);
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      color: "white"
                    }}>
                    <Typography.BLOCKQUOTE
                      style={{
                        fontFamily: "Arvo",
                        // fontWeight: 600,
                        fontSize: 20,
                        color: colors.primary.getHex()
                      }}>
                      {/* <WmkLink to={videoCat.videos[0].slug}> */}
                      {videoCat.title}
                      {/* </WmkLink> */}
                    </Typography.BLOCKQUOTE>
                  </button>
                </Col>
              </Row>
            );
          })}
          {/* <Row>
            <Col>
              <WmkLink to={`/courses/${pillar.slug}`}>
                <Typography.BLOCKQUOTE
                  style={{
                    textTransform: "uppercase",
                    color: colors.secondary.getHex(),
                    textDecoration: "underline"
                  }}>
                  {"< View all video topics"}
                </Typography.BLOCKQUOTE>
              </WmkLink>
            </Col>
          </Row> */}
        </Background>
      </StyledVideoSelector>
    </>
  );
};

export default VideoSelector;

const query = graphql`
  {
    logo: contentfulAsset(title: { eq: "WMK-Icon" }) {
      gatsbyImageData(quality: 80)
      title
      file {
        url
        contentType
      }
      description
    }
  }
`;
