import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import {
  BsFileEarmarkText,
  BsFileEarmarkPlay,
  BsFileEarmarkImage
} from "react-icons/bs";

const StyledButton = styled.div`
  a,
  a > * {
    font-size: 16px !important;
    color: ${colors.green.getHex()};
    transition: all 0.3s ease;
    text-decoration: none;
  }
  a:hover,
  a > *:hover {
    color: ${colors.charcoal.getHex()};
    transition: all 0.3s ease;
  }
  svg {
    position: relative;
    top: -3px;
    margin-right: 0.3rem;
  }
`;

const ResourceButton = ({
  to,
  target,
  children,
  fileType
}: {
  to: string;
  target?:
    | "_blank"
    | "blank"
    | "_parent"
    | "parent"
    | "_self"
    | "self"
    | "_top"
    | "top";
  children: React.ReactNode;
  fileType: string;
}) => {
  const getIcon = () => {
    if (fileType.includes("document") || fileType.includes("pdf")) {
      return <BsFileEarmarkText style={{ color: colors.bodyText.getHex() }} />;
    } else if (fileType.includes("video")) {
      return <BsFileEarmarkPlay style={{ color: colors.bodyText.getHex() }} />;
    } else if (fileType.includes("image")) {
      return <BsFileEarmarkImage style={{ color: colors.bodyText.getHex() }} />;
    }
  };
  return (
    <StyledButton>
      <Typography.BLOCKQUOTE>
        <WmkLink to={to} target={target ? target : undefined}>
          {getIcon()}
          {children}
        </WmkLink>
      </Typography.BLOCKQUOTE>
    </StyledButton>
  );
};

export default ResourceButton;
