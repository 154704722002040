import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Video } from "../../classes/Video";
import { Typography } from "../ui/Typography";
import { colors } from "../ui/colors";
import { VideoCat } from "../../classes/VideoCat";
import ReactPlayer from "react-player";
import MainButton from "../Buttons/MainButton";
import ResourceButton from "../Buttons/ResourceButton";
import TextButton from "../Buttons/TextButton";
import { slugifyTitle } from "wmk-tag";

const VideoDisplayContainer = styled(Container)<{ active: string }>`
  padding: 0 10vw;
  .about-content {
    margin: 1rem;
    margin-bottom: 8rem;
  }
  .resources {
    margin: 1rem;
    margin-bottom: 8rem;
  }
  .ask-an-engineer > * {
    display: inline-block;
    &.p {
      margin-right: 1rem;
    }
  }
  .about-content {
    .p {
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 992px) {
    padding: 0 10vw;
    position: relative;
    left: -12.5%;
    width: 75%;
  }
`;

const SubButton = styled(Col)<{ active: string }>`
  display: flex;
  justify-content: center;
  color: ${colors.bodyText.getHex()};
  padding: 1rem;
  cursor: pointer;
  border-bottom: 2px solid #e4effa;
  transition: all 0.3s ease;
  ${({ active }) =>
    active === "active"
      ? `border-bottom: solid #394A58 2px`
      : `border-bottom: solid #e4effa 2px`};
`;

const WatermarkButton = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  & > div {
    display: flex !important;
    justify-content: right;
  }
  a {
    align-self: center;
  }
`;

const WatermarkButtonWhite = styled.div`
  & > div {
    display: flex !important;
    width: 100%;
    text-align: center;
  }
  a {
    background: white;
    color: ${colors.orange.getHex()};
    width: 100%;
  }
`;

const DetailContainer = styled(Container)`
  padding: 0;
  @media screen and (min-width: 992px) {
    // padding: 0 10vw;
  }
`;

const VideoDisplayComp = ({
  video,
  videoCat,
  videoCats,
  pillarName
}: {
  video: Video;
  videoCat: VideoCat;
  videoCats: VideoCat[];
  pillarName: string;
}) => {
  const [currentDisplay, setCurrentDisplay] = useState("About");
  const [viewWidth, setViewWidth] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = videoRef.current;
    const handleResize = () => {
      const rect = current ? current.getBoundingClientRect() : null;
      if (rect) {
        setVideoWidth(rect.width);
      }
      setViewWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (setting: string) => {
    setCurrentDisplay(setting);
  };

  const secondTitleStyle = {
    fontSize: "13px",
    fontWeight: 700,
    color: colors.orange.getHex(),
    textTransform: "uppercase",
    fontFamily: "Montserrat"
  };

  const titleStyle = {
    fontFamily: "Arvo",
    fontWeight: 400,
    fontSize: "24px"
  };

  const getNextVideo = () => {
    let newVideo: Video;
    const nextVideo =
      Array.isArray(videoCat?.videos) && videoCat.videos.length
        ? videoCat.videos.reduce<Video | VideoCat | null>(
            (acc, currVideo, i) => {
              if (
                currVideo.title === video.title &&
                videoCat.videos.length > 1 &&
                videoCat.videos.length !== i + 1
              ) {
                acc = currVideo;
                return acc;
              } else if (
                acc &&
                acc.title === video.title &&
                currVideo.title !== acc.title
              ) {
                newVideo = currVideo;
                return newVideo;
              } else if (
                newVideo instanceof Video ||
                videoCat.videos.length < 2
              ) {
                return videoCats.reduce<Video | VideoCat | null>(
                  (accum, currCat) => {
                    if (currCat.title === videoCat.title) {
                      accum = currCat;
                      return accum;
                    } else if (accum && accum.title === videoCat.title) {
                      newVideo = currCat.videos[0];
                      return newVideo;
                    } else {
                      return newVideo;
                    }
                  },
                  videoCat
                );
              } else {
                return null;
              }
            },
            video
          )
        : null;
    return nextVideo;
  };

  const askAnEngineer = (
    <div className="ask-an-engineer">
      <Typography.P style={{ fontFamily: "Montserrat" }}>
        Have questions?{" "}
      </Typography.P>
      <TextButton to="https://dipra.org/ask-an-expert/ask-an-engineer" arrow>
        {" "}
        Ask an Engineer
      </TextButton>{" "}
    </div>
  );

  const nextVideo = getNextVideo();

  const nextVideoSlug =
    nextVideo && "slug" in nextVideo && nextVideo.slug ? nextVideo.slug : "";

  return (
    <>
      <VideoDisplayContainer fluid>
        <Row>
          <Col style={{ marginTop: "1rem" }}>
            <TextButton
              to={
                pillarName ? `/pillars/${slugifyTitle(pillarName)}` : `/pillars`
              }>
              {"< Return to all courses"}
            </TextButton>
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: 0 }} ref={videoRef}>
            <ReactPlayer
              url={video?.videoLink ? video.videoLink : video.video}
              width={"100%"}
              height={videoWidth * 0.5625}
              controls
            />
          </Col>
        </Row>
        <DetailContainer fluid>
          <Row style={{ padding: "2rem", borderBottom: `2px solid #E4EFFA` }}>
            {viewWidth > 570 && (
              <>
                <Col>
                  <Typography.P style={secondTitleStyle}>
                    {pillarName}
                  </Typography.P>
                  <Typography.BLOCKQUOTE style={titleStyle}>
                    {video.title}
                  </Typography.BLOCKQUOTE>
                </Col>
                <Col>
                  <WatermarkButton>
                    <MainButton
                      to={
                        nextVideoSlug ? `/videos/${nextVideoSlug}` : `/pillars`
                      }>
                      {nextVideoSlug ? `Next Video` : `View Other Courses`}
                    </MainButton>
                  </WatermarkButton>
                </Col>
              </>
            )}
            {viewWidth <= 569 && (
              <>
                <Typography.P style={secondTitleStyle}>
                  {pillarName}
                </Typography.P>
                <Typography.BLOCKQUOTE style={titleStyle}>
                  {video.title}
                </Typography.BLOCKQUOTE>
                <WatermarkButtonWhite>
                  <MainButton
                    to={
                      nextVideoSlug ? `/videos/${nextVideoSlug}` : `/pillars`
                    }>
                    {nextVideoSlug ? `Next Video` : `View Other Courses`}
                  </MainButton>
                </WatermarkButtonWhite>
              </>
            )}
          </Row>
          <Row>
            <SubButton
              className="sub-buttons"
              onClick={() => {
                handleClick("About");
              }}
              active={currentDisplay === "About" ? "active" : ""}>
              <Typography.H6 style={{ fontWeight: 400, fontFamily: "Arvo" }}>
                {"About"}
              </Typography.H6>
            </SubButton>
            <SubButton
              className="sub-buttons"
              onClick={() => handleClick("Resources")}
              active={currentDisplay === "Resources" ? "active" : ""}>
              <Typography.H6 style={{ fontWeight: 400, fontFamily: "Arvo" }}>
                {"Resources"}
              </Typography.H6>
            </SubButton>
          </Row>
          <Row>
            {currentDisplay === "About" && (
              <Col className="about-content">
                <Typography.P
                  style={{
                    color: colors.bodyText.getHex(),
                    fontFamily: "Montserrat"
                  }}>
                  {video.about}
                </Typography.P>
                {askAnEngineer}
              </Col>
            )}
            {currentDisplay === "Resources" && (
              <Col className="resources">
                {Array.isArray(video.resources) &&
                  video.resources.map((resource, i) => {
                    return (
                      <ResourceButton
                        to={`${resource.file.url}`}
                        fileType={resource.file.contentType}
                        key={resource.title + i}>
                        {resource.title}
                      </ResourceButton>
                    );
                  })}
                {askAnEngineer}
              </Col>
            )}
          </Row>
        </DetailContainer>
      </VideoDisplayContainer>
    </>
  );
};

export default VideoDisplayComp;
