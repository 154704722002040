import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import { Pillar } from "../classes/Pillar";
import { Video } from "../classes/Video";
import { VideoCat } from "../classes/VideoCat";
import VideoDisplayComp from "../components/Blocks/VideoDisplay";
import VideoSelector from "../components/Blocks/VideoSelector";
import { PillarQuery } from "../fragments/NodePillarFields";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { VideoCategoryQuery } from "../fragments/NodeVideoCategoryFields";
import { VideoQuery } from "../fragments/NodeVideoFields";
import { useState, useEffect } from "react";
import { Typography } from "../components/ui/Typography";
import { WmkLink } from "wmk-link";

interface VideoNodeQuery {
  data: {
    pillar: PillarQuery;
    site: SiteMetaDataFields;
    video: VideoQuery;
    videoCat: VideoCategoryQuery;
  };
}

const VideoNode = ({ data }: VideoNodeQuery) => {
  const [redirect, setRedirect] = useState(false);
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true)
    }
  },[])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirect) {
        window.location.href = `/`;
      }
    }, 1000);
    if (validUser) {
      clearTimeout(timer);
    }
  }, [redirect, validUser]);

  useEffect(() => {
    setTimeout(() => {
      if (!validUser) {
        setRedirect(true);
      }
    }, 1000);
  }, [validUser]);
  const pillar = new Pillar(data.pillar);

  const meta = data.site.siteMetadata;
  const video = new Video(data.video);
  const videoCat = new VideoCat(data.videoCat);
  const videoCats = data?.pillar?.videoCategories.map((vidCat) => {
    return new VideoCat(vidCat);
  });


  return (
    <>
      <WmkSeo.Meta
        title={data?.video?.title}
        slug={`videos/${data.video.slug}`}
        siteTitle={data?.site?.siteMetadata?.title}
        baseUrl={data?.site?.siteMetadata?.baseUrl}
        description={video.about ? video.about : video.title}
        ogImageUrl=""
        twitterImageUrl=""
        twitterHandle=""
      />
      {validUser ? (
        <>
          {/* <WmkSeo.Meta
            // title={meta.title}
            // slug={video.slug}
            // siteTitle={meta?.title}
            // baseUrl={meta?.baseUrl}
            description={video.about ? video.about : video.title}
            twitterHandle=""
            ogImageUrl=""
            twitterImageUrl=""
          /> */}
          <VideoDisplayComp
            video={video}
            videoCats={videoCats}
            videoCat={videoCat}
            pillarName={data.pillar?.title}
          />
          <VideoSelector
            video={video}
            videoCats={videoCats}
            videoCat={videoCat}
            pillar={pillar}
          />
        </>
      ) : (
        <>
          <Typography.H3 style={{ textAlign: "center", margin: "0 10%" }}>
            Please go to the <WmkLink to="/">home page</WmkLink> and fill out
            the form to access this free content.
          </Typography.H3>
        </>
      )}
    </>
  );
};

export default VideoNode;

export const query = graphql`
  query ($slug: String!) {
    pillar: contentfulPillar(
      videoCategories: {
        elemMatch: { videos: { elemMatch: { slug: { eq: $slug } } } }
      }
    ) {
      contentfulid
      ...NodePillarFields
    }
    site {
      ...NodeSiteMetadata
    }
    video: contentfulVideo(slug: { eq: $slug }) {
      ...NodeVideoFields
    }
    videoCat: contentfulVideoCategory(
      videos: { elemMatch: { slug: { eq: $slug } } }
    ) {
      ...NodeVideoCategoryFields
    }
  }
`;
